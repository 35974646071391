import { gql } from "@medbillai/graphql-types";

export const getIssuesQuery = gql(/* GraphQL */ `
  query QueryIssues($input: QueryIssuesInput) {
    queryIssues(input: $input) {
      __typename
      id
      title
      openedAt
      closedAt
      lastEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
      firstUnseenEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
    }
  }
`);
